<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-card :title="heading">

                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                        <div class="alert-body">
                            {{error_message}}
                        </div>
                    </b-alert>
                
                    <b-form @submit.prevent="formSubmit">
                        <b-row>
                            <b-col md="12">

                                <b-row>
                                    <b-col md="2">Graphics<span class="text-danger"> *</span></b-col>
                                    <b-col md="10">Location<span class="text-danger"> *</span></b-col>
                                </b-row>

                                <b-row class="mt-1">
                                    <b-col md="2">
                                        <AddImage v-if="form.graphics == null" v-bind:index="0"/>
                                        <EditImage v-else v-bind:imageData="form.graphics"/>
                                    </b-col>
                                    <b-col md="10">
                                        <b-row>
                                            <b-col md="12">
                                                <b-form-input size="lg" placeholder="3 to 200 characters" v-model="form.location_name" />
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <hr>
                                            
                                <b-row>
                                    <b-col>
                                        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1">
                                            Submit
                                        </b-button>

                                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.go(-1)">
                                            Cancel
                                        </b-button>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import {
        BFormTags, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile, BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import vSelect from 'vue-select';
    import AddImage from './AddImage.vue';
    import EditImage from './EditImage.vue';
    import Bus from "../../../event-bus";

    export default {
        components: {
            BFormTags, AddImage, EditImage, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile, BBreadcrumb
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,

                form : {
                    location_name : null,
                    graphics : null,
                    id: ''
                },

                // Page Labels
                heading : 'Edit Location',
                api     : '/api/add-locations-for-mpr',
            }
        },

        methods : {
            formSubmit(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form,
                    },
                    api : this.api
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;

                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;

                        this.successAlert().then((result) => {
                            this.$router.go(-1);
                        });
                    }
                });
            },

            getDetail(){
                return this.$store.dispatch(POST_API, {
                    data: {
                        id : this.$route.params.id
                    },
                    api: '/api/locations-for-mpr-detail'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data = this.$store.getters.getResults.data;
                        
                        this.form.question_name = data.question_name;
                        this.form.site          = data.site;

                        this.form.location_name = data.location_name;
                        this.form.graphics = data.graphics;
                        this.form.id = data._id;
                    }
                });
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:{name:'custom-forms'},
                text: 'Custom Forms',
              },{
                to:{name:'mosquito-prevention-report'},
                text:'Mosquito Prevention Report',
                
              },{
                to:{name:'locations-for-mpr'},
                text:'Locations'
              },{
                to:null,
                text:'Edit Location'
              }];
              return item;
            }
        },
        mounted(){
            if(this.$route.name == 'edit-locations-for-mpr'){
                this.form.id = this.$route.params.id;
                this.heading = 'Edit Location'
                this.api     = '/api/update-locations-for-mpr'
                
                this.getDetail();
            }

            // to add image
            Bus.$on('update-image', (data) => {
                this.form.graphics = data.data;
            })
        }
    }
</script>
